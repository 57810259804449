<template>
  <div class="comment-reply" v-if="replyComment">
    <div class="comment-reply-left">
      <span>{{ $locale.words["reply"] + " " + $locale.words["to"] + " " }}</span>
      <span>@{{ replyComment.user.user }}</span>
    </div>
    <div class="comment-reply-right">
      <a class="comment-reply-button __cancel" @click="cancelReplyComment">
        <span>{{ $locale.words["cancel"] }}</span>
        <span v-if="!$isMobile">{{ " " + lowercase($locale.words["reply"]) }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    cancelReplyComment: function() {
      this.$store.commit("setReplyComment", null);
    },
  },
  computed: {
    replyComment: function() {
      return this.$store.state.comments.replyComment;
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-reply {
  padding: 0 0 $mpadding/2 0;
  @include Flex(inherit, space-between, center);
  &-button {
    cursor: pointer;
    &:active {
      opacity: 0.6;
    }
    &:hover {
      color: $primary_color;
    }
  }
}
</style>
